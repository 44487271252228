/* @import '~mdb-ui-kit/css/mdb.min.css'; */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
* {
  font-family: 'Inter', sans-serif;
}
html,
body,
#root {
  height: 100%;
}

#root > footer {
  position: sticky;
  top: 100vh;
}
